<template>
  <order-attachments
    ref="orderAttachmentsRef"
    v-bind="$attrs"
    accept="image/*,application/pdf"
    analyze
    confirm-delete-modal-text="Your scanned items will not be deleted."
    hide-attachments
    :model-value="attachments"
    multiple
    :title="`Attach ${attachmentLabel}`"
    @attachment-added="attachmentSelected"
  >
    <template v-slot:label="{ selectFile }">
      <div class="py-6">
        <div class="qtm-h4 text-mid-light-grey">
          Drag and drop here to get started or
        </div>
        <qtm-btn class="mt-4" @click="selectFile">
          Attach {{ attachmentLabel }}
        </qtm-btn>
      </div>
    </template>

    <attachment-preview
      v-for="(attachment, i) in attachments"
      :key="attachment.id"
      :attachment="attachment"
      class="py-4"
      :class="{ 'qtm-border-bottom': i < attachments.length - 1 }"
    >
      <template v-slot:name-append>
        <attachment-scan-status
          class="ml-4"
          :attachment-label="attachmentLabel"
          :status="scanStatuses[attachment.id]"
        />
      </template>
      <template v-slot:action>
        <qtm-btn class="px-3 vertical-separator" tertiary @click="$emit('preview', attachment)">
          <v-icon class="mr-2">
            mdi-eye
          </v-icon>
          View {{ attachmentLabel }}
        </qtm-btn>
        <delete-btn v-if="!displayOnly" class="ml-2 mr-n1" @click="orderAttachmentsRef?.removeFile(attachment, 0)" />
      </template>
    </attachment-preview>

    <teleport v-if="attachmentListTarget" :to="attachmentListTarget">
      <div class="qtm-body text-dark-grey">
        <div v-for="attachment in attachments" :key="attachment.id" class="my-4">
          <v-tooltip location="top">
            <template v-slot:activator="{ props: activator }">
              <span
                v-bind="activator"
                class="cursor-pointer text-decoration-underline"
                @click="$emit('preview', attachment)"
                v-text="attachment.name"
              />
            </template>
            View {{ attachmentLabel }}
          </v-tooltip>
          <attachment-scan-status
            :attachment-label="attachmentLabel"
            class="ml-4"
            :status="scanStatuses[attachment.id]"
          />
        </div>
      </div>
    </teleport>
  </order-attachments>
</template>

<script setup lang="ts">
import type { Attachment, LineItem } from '@quotetome/materials-api'
import AttachmentPreview from '@/components/attachments/attachment-preview.vue'
import AttachmentScanStatus from '@/components/attachments/attachment-scan-status.vue'
import DeleteBtn from '@/components/delete-btn.vue'
import OrderAttachments from '@/components/orders/order-attachments.vue'
import parseItemAnalysis, { parseFromSummary } from '@/utils/parse-analysis'
import type { ScanStatus } from '@/composables/scan-status'

export interface Props {
  attachmentLabel: string
  attachmentListTarget?: HTMLElement
  attachments: Attachment[]
  costCode?: string
  displayOnly?: boolean
  items?: LineItem[]
  scanItemsSku?: boolean
  units?: any[]
}

const props = withDefaults(defineProps<Props>(), {
  attachmentListTarget: undefined,
  costCode: '',
  items: () => [],
  units: () => [],
})
const emit = defineEmits([
  'cancelled',
  'failure',
  'preview',
  'started',
  'success',
  'update:attachments',
  'update:invoice-number',
  'update:issue-date',
  'update:items',
  'update:order',
  'update:po-number',
  'update:scanning',
])

const checkCounts: {[key: number]: number} = {}
const orderAttachmentsRef = ref<InstanceType<typeof OrderAttachments>>()
const scanStatuses = ref<{[key: number]: ScanStatus}>({})

const checkTimeout = ref<ReturnType<typeof setTimeout> | undefined>()
onBeforeUnmount(() => clearTimeout(checkTimeout.value))
const TIMEOUT_LEN = 5000
const { $api } = useNuxtApp()
// eslint-disable-next-line require-await
const checkAttachments = async () => {
  const attachmentIds = props.attachments
    .filter(a => a.id)
    .map(a => a.id)
    .filter(id => scanStatuses.value[id] === PENDING)

  attachmentIds.forEach(async id => {
    checkCounts[id] += 1

    try {
      const results = await $api.v1.attachments.analyze(id)

      if (results?.JobStatus === 'IN_PROGRESS') {
        return
      }

      if (results?.JobStatus === 'SUCCEEDED') {
        const unit = props.units?.find(u => u.is_default)?.code || 'Each'
        const items = parseItemAnalysis(results, unit, props.scanItemsSku, props.costCode)
        const success = items.length > 0

        emit('update:items', props.items.concat(items))
        scanStatuses.value[id] = success ? SUCCESS : ERROR

        if (success) {
          emit('success', { check_count: checkCounts[id], item_count: items.length })
        }
        else {
          emit('failure', { check_count: checkCounts[id], reason: 'no items' })
        }

        const poNumber = parseFromSummary(results, 'PO_NUMBER')
        if (poNumber) {
          emit('update:po-number', poNumber)
        }

        const invoiceNumber = parseFromSummary(results, 'INVOICE_RECEIPT_ID')
        if (invoiceNumber) {
          emit('update:invoice-number', invoiceNumber)
        }

        let invoiceDate = parseFromSummary(results, 'INVOICE_RECEIPT_DATE')
        if (invoiceDate) {
          emit('update:issue-date', invoiceDate)
        }
        else {
          invoiceDate = parseFromSummary(results, 'ORDER_DATE')
          if (invoiceDate) {
            emit('update:issue-date', invoiceDate)
          }
        }
      }
      else {
        scanStatuses.value[id] = ERROR
        emit('failure', { check_count: checkCounts[id], reason: 'job status' })
      }
    }
    catch (error) {
      scanStatuses.value[id] = ERROR
      emit('failure', { check_count: checkCounts[id], reason: 'error' })
      console.error(error)
    }
  })

  const pendingAttachment = props.attachments.some(a => a.id && scanStatuses.value[a.id] === PENDING)

  if (pendingAttachment) {
    checkTimeout.value = setTimeout(checkAttachments, TIMEOUT_LEN)
  }
  else {
    checkTimeout.value = undefined
  }
}
const attachmentSelected = (attachment: Attachment) => {
  if (attachment) {
    emit('started')
    checkCounts[attachment.id] = 0
    scanStatuses.value[attachment.id] = PENDING

    if (!checkTimeout.value) {
      checkTimeout.value = setTimeout(checkAttachments, TIMEOUT_LEN)
    }
  }
}

const ordersStore = useOrdersStore()

onMounted(async () => {
  if (props.attachments.length && !props.items.length && !props.displayOnly) {
    props.attachments.forEach(attachment => {
      checkCounts[attachment.id] = 0
      scanStatuses.value[attachment.id] = PENDING
    })
    checkAttachments()
  }
  else if (ordersStore.attachment) {
    const attachment = ordersStore.attachment

    ordersStore.setAttachment(null)
    emit('update:attachments', [attachment])
    scanStatuses.value[attachment.id] = PENDING
    await nextTick()
    checkAttachments()

    if (attachment.from_order) {
      try {
        const order = await $api.v1.rfqs.get(attachment.from_order)

        emit('update:order', order)
      }
      catch (error) {
        console.error(error)
      }
    }
  }
})

watch(checkTimeout, () => emit('update:scanning', !!checkTimeout.value))
</script>
